/* ----------------------------------------------------------------VARIABLES-------------------------------------------------------------- */
:root {
  --h: 210;
  --primary-color: hsl(var(--h), 68%, 44%);
  --primary-color-light: hsl(var(--h), 68%, 50%);
  --primary-color-lighter: hsl(var(--h), 68%, 61%);
  --secondary-color: #32c589;
  --secondary-color-light: hsla(0, 0%, 97%, 90%);
  --secondary-color-darker: hsla(0, 0%, 87%, 90%);
  --jph-dorado: #c3a169;
  --submenu-color: #1571c1;
  --tertiary-color: #404756;
  --quaternary-color: #F07E9B;
  --quinary-color: #C53F32;
  --senary-color: #DAA21B;
  --primary-font-color: rgb(71, 71, 71);
  --primary-font: "Montserrat", sans-serif;
  --secundary-font: "Open Sans", sans-serif;
  --box-shadow-center-light: 0 0 6px rgb(0 0 0 / 15%);
  --box-shadow-bottom-light: 0 3px 6px rgb(0 0 0 / 15%);
  --box-shadow-low: 0 12px 25px rgba(0, 0, 0, .09);
  --box-shadow-mid: 0 12px 25px rgba(0, 0, 0, .18);
  --box-shadow-high: 0 12px 25px rgba(0, 0, 0, .25);
  --font-size-grande: clamp(1.5rem, 4.3vw, 2.4rem);
  --font-size-mediana: clamp(1.2rem, 2.8vw, 2rem);
  --font-size-normal: clamp(1rem, 2.5vw, 1.3rem);
  --font-size-chica: clamp(.8rem, 2.3vw, .9rem);
  --font-size-boton: clamp(.9rem, 2.5vw, 1.1rem);
}

/* ----------------------------------------------------------------- CLASES--------------------------------------------------------------- */
.bg-blanco {
  background-color: white !important;
}

.bg-verde-esmeralda {
  background-color: #2fc193 !important;
}

li.noleido {
  background: #eeefff;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-secondary {
  background-color: var(--secondary-color) !important;
}

.bg-tertiary {
  background-color: var(--tertiary-color) !important;
}

.bg-quaternary {
  background-color: var(--quaternary-color) !important;
}

.bg-quinary {
  background-color: var(--quinary-color) !important;
}

.bg-senary {
  background-color: var(--senary-color) !important;
}

.font-blanco {
  color: #fafafa !important;
}

.font-chica {
  font-size: var(--font-chica);
}

.font-normal {
  font-size: var(--font-normal);
}

.font-mediana {
  font-size: var(--font-mediana);
}

.font-grande {
  font-size: var(--font-grande);
}

.h100 {
  height: 100%;
}

.oculto {
  display: none !important;
}

/* ----------------------------------------------------- Resets ------------------------------------------------------------------------- */
body {
  font-size: 12px;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #707478;
  background: hsla(var(--h), 68%, 61%, 0.23);
}

a:hover {
  text-decoration: none;
}

h1 {
  font-weight: 400 !important;
}

input:focus,
select:focus {
  outline: none;
}

input {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 31px;
}

input[type=checkbox] {
  height: 15px !important;
}

.content {
  padding: 10px 10px;
  background-color: transparent;
}
.content .page-header {
  margin: 4rem 3rem 1rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #605a5a;
}

select.primary,
select.primary:focus,
option.primary {
  background-color: var(--primary-color);
  color: #FFF;
}

select.secondary,
select.secondary:focus,
option.secondary {
  background-color: var(--secondary-color);
  color: #FFF;
}

select.tertiary,
select.tertiary:focus,
option.tertiary {
  background-color: var(--tertiary-color);
  color: #FFF;
}

select.quaternary,
select.quaternary:focus,
option.quaternary {
  background-color: var(--quaternary-color);
  color: #FFF;
}

select.quinary,
select.quinary:focus,
option.quinary {
  background-color: var(--quinary-color);
  color: #FFF;
}

select.senary,
select.senary:focus,
option.senary {
  background-color: var(--senary-color);
  color: #FFF;
}

@-webkit-keyframes animate {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 500px 0;
  }
}
@-moz-keyframes animate {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 500px 0;
  }
}
@-ms-keyframes animate {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 500px 0;
  }
}
@-o-keyframes animate {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 500px 0;
  }
}
@keyframes animate {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 500px 0;
  }
}
body {
  height: 100vh;
  background: #2980b9 url("../images/background.png") repeat 0 0;
  -webkit-animation: 10s linear 0s normal none infinite animate;
  -moz-animation: 10s linear 0s normal none infinite animate;
  -ms-animation: 10s linear 0s normal none infinite animate;
  -o-animation: 10s linear 0s normal none infinite animate;
  animation: 10s linear 0s normal none infinite animate;
}

a {
  color: #fafafa;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
  padding: 8px 15px !important;
}
a:hover {
  color: #fff;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #ccc;
}

h6 {
  color: #fafafa;
  font-size: 0.7rem;
}

/* STRUCTURE */
.col-sm-3 {
  padding-left: 7px !important;
}

.col-sm-9 {
  padding-right: 7px !important;
}

.col-md-10 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: rgba(255, 255, 255, 0.2);
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

.form-group {
  display: flex;
  justify-content: center;
}

#formFooter {
  background-color: rgba(255, 255, 255, 0.2);
  border-top: 1px solid #8cb8d9;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */
h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/
.col-form-label {
  font-size: 1.5em;
  color: #fafafa;
  font-weight: 400;
  text-align: start;
  padding-left: 0;
}

input[type=button],
input[type=submit],
input[type=reset] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 5px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type=button]:hover,
input[type=submit]:hover,
input[type=reset]:hover {
  background-color: #39ace7;
}

input[type=button]:active,
input[type=submit]:active,
input[type=reset]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type=text],
input[type=password] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type=text]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */
/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  text-decoration: none;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */
*:focus {
  outline: none;
}

.input-block-level {
  width: 90% !important;
  margin-left: 13px !important;
}