/* ----------------------------------------------------------------VARIABLES-------------------------------------------------------------- */

:root {
    //++++++++++++++++++++++++++++
    //+++++++++ COLORES ++++++++++
    //++++++++++++++++++++++++++++
    // BACKGROUND
    --h: 210;
    --primary-color: hsl(var(--h), 68%, 44%);
    --primary-color-light: hsl(var(--h), 68%, 50%);
    --primary-color-lighter: hsl(var(--h), 68%, 61%);
    --secondary-color: #32c589;
    --secondary-color-light: hsla(0, 0%, 97%, 90%);
    --secondary-color-darker: hsla(0, 0%, 87%, 90%);
    --jph-dorado: #c3a169;
    --submenu-color: #1571c1;
    --tertiary-color: #404756;
    --quaternary-color: #F07E9B;
    --quinary-color: #C53F32;
    --senary-color: #DAA21B;
    //  FUENTES (COLORES)
    --primary-font-color: rgb(71, 71, 71);
    //FUENTES  
    --primary-font: "Montserrat", sans-serif;
    --secundary-font: "Open Sans", sans-serif;
    //Box shadows
    --box-shadow-center-light: 0 0 6px rgb(0 0 0 / 15%);
    --box-shadow-bottom-light: 0 3px 6px rgb(0 0 0 / 15%);
    --box-shadow-low: 0 12px 25px rgba(0, 0, 0, .09);
    --box-shadow-mid: 0 12px 25px rgba(0, 0, 0, .18);
    --box-shadow-high: 0 12px 25px rgba(0, 0, 0, .25);
    //Tamaño de fuentes
    --font-size-grande: clamp(1.5rem, 4.3vw, 2.4rem);
    --font-size-mediana: clamp(1.2rem, 2.8vw, 2rem);
    --font-size-normal: clamp(1rem, 2.5vw, 1.3rem);
    --font-size-chica: clamp(.8rem, 2.3vw, .9rem);
    --font-size-boton: clamp(.9rem, 2.5vw, 1.1rem);
    //COLORE
}


/* ----------------------------------------------------------------- CLASES--------------------------------------------------------------- */

//BACKGROUND COLORS
.bg-blanco {
    background-color: white !important;
}

.bg-verde-esmeralda {
    background-color: #2fc193 !important;
}

li.noleido {
    background: #eeefff;
}

.bg-primary {
    background-color: var(--primary-color) !important;
}

.bg-secondary {
    background-color: var(--secondary-color) !important;
}

.bg-tertiary {
    background-color: var(--tertiary-color) !important;
}

.bg-quaternary {
    background-color: var(--quaternary-color) !important;
}

.bg-quinary {
    background-color: var(--quinary-color) !important;
}

.bg-senary {
    background-color: var(--senary-color) !important;
}

//COLORES FUENTE
.font-blanco {
    color: #fafafa !important;
}

//TAMAÑO FUENTE
.font-chica {
    font-size: var(--font-chica);
}

.font-normal {
    font-size: var(--font-normal);
}

.font-mediana {
    font-size: var(--font-mediana);
}

.font-grande {
    font-size: var(--font-grande);
}

//CONTENEDORES
.h100 {
    height: 100%;
}

//UTILIDADES
.oculto {
    display: none !important;
}


/* ----------------------------------------------------- Resets ------------------------------------------------------------------------- */

body {
    font-size: 12px;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #707478;
    background: hsla(var(--h), 68%, 61%, .23);
}

a:hover {
    text-decoration: none;
}

h1 {
    font-weight: 400 !important;
}

input:focus,
select:focus {
    outline: none;
}

input {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    height: 31px;
}

input[type="checkbox"] {
    height: 15px !important;
}

// RESETEO COLOR ADMIN
.content {
    padding: 10px 10px;
    background-color: transparent;
    .page-header {
        margin: 4rem 3rem 1rem;
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #605a5a;
    }
}

select.primary,
select.primary:focus,
option.primary {
    background-color: var(--primary-color);
    color: #FFF;
}

select.secondary,
select.secondary:focus,
option.secondary {
    background-color: var(--secondary-color);
    color: #FFF;
}

select.tertiary,
select.tertiary:focus,
option.tertiary {
    background-color: var(--tertiary-color);
    color: #FFF;
}

select.quaternary,
select.quaternary:focus,
option.quaternary {
    background-color: var(--quaternary-color);
    color: #FFF;
}

select.quinary,
select.quinary:focus,
option.quinary {
    background-color: var(--quinary-color);
    color: #FFF;
}

select.senary,
select.senary:focus,
option.senary {
    background-color: var(--senary-color);
    color: #FFF;
}