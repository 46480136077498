@-webkit-keyframes animate {
  from {background-position:0 0;}
  to {background-position: 500px 0;}
}
 
@-moz-keyframes animate {
  from {background-position:0 0;}
  to {background-position: 500px 0;}
}
 
@-ms-keyframes animate {
  from {background-position:0 0;}
  to {background-position: 500px 0;}
}
 
@-o-keyframes animate {
  from {background-position:0 0;}
  to {background-position: 500px 0;}
}
 
@keyframes animate {
  from {background-position:0 0;}
  to {background-position: 500px 0;}
}

